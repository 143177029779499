import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import feedSlice from './schemas/feedSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authSlice,
      feedSlice: feedSlice,
    },
    preloadedState
  });
};

// For client-side: 
