import { createSlice } from '@reduxjs/toolkit';

const initialState = () => ({
    feedData: [],
    feedScrollPositions: {
        home: 0,
        search: 0
    },
    foodStrip: [
        {
            name: 'Burgers',
            icon: 'burger',
            search: 'burgers'
        }
    ],
    foodStripTarget: 'all',
    mapStripTarget: 'all',
    demoMode: false,
    darkMode: false,
    webviewVisible: false,
    currentLocation: null,
    uniqueId: 0,
    foodCount: 0,
    trialActive: null,
    trialTime: 0,
    developerMode: true,
    userDemoMode: false,
    item: [],
    serviceLinks: [],
    scrollPosition: 0,
    mapPadding: 0,
    focusedColor: '#2D9379',
    focusedTitle: 'UberEats',
    quickStorage: {},
    availableCategories: [
        {
            name: ['Burgers', 'Burger', 'Hamburgers', 'Hamburger', 'Cheeseburger'],
            icon: 'burger'
        },
        { name: ['Pizza', 'Pizzeria', 'Pizza Place'], icon: 'pizza' },
        {
            name: ['Fast Food', 'Fastfood', 'Fastfood Restaurant'],
            icon: 'fastfood'
        },
        { name: ['Vegan', 'Vegetarian', 'Plant-Based'], icon: 'vegetable' },
        {
            name: ['Gluten-Free', 'Gluten Free', 'Gluten Free Options'],
            icon: 'glutenfree'
        },
        {
            name: ['American', 'American Cuisine', 'American Food'],
            icon: 'american'
        },
        {
            name: ['Sandwiches', 'Sandwich', 'Subs', 'Hoagies', 'Paninis'],
            icon: 'sandwich'
        },
        { name: ['Chicken', 'Chicken Dishes', 'Poultry'], icon: 'chicken' },
        {
            name: ['Dessert', 'Desserts', 'Sweets', 'Sweet Treats'],
            icon: 'dessert'
        },
        { name: ['Salads', 'Salad', 'Fresh Salads'], icon: 'salad' },
        { name: ['Wings', 'Chicken Wings', 'Buffalo Wings'], icon: 'wings' },
        { name: ['Breakfast', 'Morning Meals', 'Brunch'], icon: 'breakfast' },
        { name: ['Seafood', 'Fish', 'Marine Dishes'], icon: 'seafood' },
        { name: ['Asian', 'Asian Cuisine', 'Oriental Food'], icon: 'asian' },
        {
            name: ['Kids Menu', "Children's Menu", 'Family Friendly Meals'],
            icon: 'kids_menu'
        },
        { name: ['Bakery', 'Bakeshop', 'Pastries'], icon: 'bakery' },
        {
            name: ['Southern', 'Southern Cuisine', 'Southern Comfort Food'],
            icon: 'southern'
        },
        { name: ['Snacks', 'Snack Foods', 'Light Bites'], icon: 'snacks' },
        { name: ['Soup', 'Soups', 'Broth'], icon: 'soup' },
        { name: ['Japanese', 'Japanese Cuisine', 'Ramen'], icon: 'japanese' },
        { name: ['Mexican', 'Mexican Cuisine', 'Tex-Mex'], icon: 'mexican' },
        { name: ['Chinese', 'Chinese Cuisine', 'Chinese Food'], icon: 'chinese' },
        { name: ['Hot Dogs', 'Frankfurters', 'Sausages'], icon: 'hot_dogs' },
        { name: ['Sushi', 'Sashimi', 'Japanese Sushi'], icon: 'sushi' },
        { name: ['Bowls', 'Bowl Dishes', 'Rice Bowls'], icon: 'bowls' },
        { name: ['Tacos', 'Mexican Tacos', 'Taco'], icon: 'tacos' },
        { name: ['Ice Cream', 'Gelato'], icon: 'ice_cream' },
        { name: ['Yogurt', 'Frozen Yogurt', 'Yogurt Shop'], icon: 'yogurt' },
        {
            name: ['Healthy', 'Healthy Options', 'Nutritious Meals'],
            icon: 'healthy'
        },
        {
            name: ['Coffee and Tea', 'Café', 'Coffee Shop', 'Coffee'],
            icon: 'coffee_tea'
        },
        { name: ['Shakes', 'Milkshakes', 'Smoothies'], icon: 'shakes' },
        { name: ['Italian', 'Italian Cuisine', 'Pasta', 'Pizza'], icon: 'italian' },
        {
            name: ['Burritos', 'Mexican Burritos', 'Wrapped Burritos'],
            icon: 'burritos'
        },
        { name: ['Steak', 'Steakhouse', 'Steaks'], icon: 'steak' },
        { name: ['Wraps', 'Wrapped Sandwiches', 'Rolls'], icon: 'wraps' },
        { name: ['Pasta', 'Italian Pasta', 'Noodles'], icon: 'pasta' },
        { name: ['Cajun', 'Cajun Cuisine', 'Creole'], icon: 'cajun' },
        {
            name: ['Convenience', 'Convenience Store', 'Quick Bites'],
            icon: 'convenience'
        },
        {
            name: ['Lunch Specials', 'Lunch Deals', 'Midday Meals'],
            icon: 'lunch_specials'
        },
        { name: ['Noodles', 'Asian Noodles', 'Ramen'], icon: 'noodles' },
        { name: ['Deli', 'Deli Counter', 'Sandwich Shop'], icon: 'deli' },
        { name: ['Low Carb', 'Keto', 'Low Carbohydrate'], icon: 'low_carb' },
        { name: ['Grill', 'Grilled Foods', 'Barbecue'], icon: 'grill' },
        {
            name: ['Subs', 'Submarine Sandwiches', 'Hoagies', 'Sandwiches'],
            icon: 'subs'
        },
        {
            name: ['Chicken Wings', 'Wings', 'Buffalo Wings'],
            icon: 'chicken_wings'
        },
        {
            name: ['Meatballs', 'Meatball Dishes', 'Italian Meatballs'],
            icon: 'meatballs'
        },
        { name: ['BBQ', 'Barbecue', 'Grill'], icon: 'bbq' }
    ]
});

const feedSlice = createSlice({
    name: 'Location Manager',
    initialState: initialState(),
    reducers: {
        resetFeed: (state) => {
            state = initialState();
        },

        addFeedItems: (state, action) => {
            state.feedData = action.payload;
        },

        setFeedScrollPosition: (state, action) => {
            const { screen, position } = action.payload;
            state.feedScrollPositions[screen] = position;
        },
        setFoodStripTarget: (state, action) => {
            state.foodStripTarget = action.payload;
        },
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setDemoMode: (state, action) => {
            state.demoMode = action.payload;
        },
        setUniqueId: (state, action) => {
            state.uniqueId = action.payload;
        },
        setTrialActive: (state, action) => {
            state.trialActive = action.payload;
        },
        setTrialTime: (state, action) => {
            state.trialTime = action.payload;
        },
        setFoodCount: (state, action) => {
            state.foodCount = action.payload;
        },
        setDeveloperMode: (state, action) => {
            state.developerMode = action.payload;
        },
        setItem: (state, action) => {
            state.item = action.payload;
        },
        setServiceLinks: (state, action) => {
            state.serviceLinks = action.payload;
        },
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
        setFocusedColor: (state, action) => {
            state.focusedColor = action.payload;
        },
        setFocusedTitle: (state, action) => {
            state.focusedTitle = action.payload;
        },
        setUserDemoMode: (state, action) => {
            state.userDemoMode = action.payload;
        },
        setMapPadding: (state, action) => {
            state.mapPadding = action.payload;
        },
        setMapStripTarget: (state, action) => {
            state.mapStripTarget = action.payload;
        },
        // add new item to quick storage
        addQuickStorageItem: (state, action) => {
            const { key, value } = action.payload;
            state.quickStorage[key] = value;
            console.log('quickStorage ADD:', state.quickStorage);
        },
        updateQuickStorageItem: (state, action) => {
            const { key, value } = action.payload;
            if (state.quickStorage[key]) {
                state.quickStorage[key] = { ...state.quickStorage[key], ...value };
                console.log('quickStorage UPDATE:', state.quickStorage);
            }
        },
        removeQuickStorageItem: (state, action) => {
            const { key } = action.payload;
            delete state.quickStorage[key];
            console.log('quickStorage REMOVE:', state.quickStorage);
        },
        // Load initial data from AsyncStorage
        setQuickStorage: (state, action) => {
            state.quickStorage = action.payload;
            console.log('quickStorage SET:', state.quickStorage);
        },
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload;
        }
    }
});

export const {
    resetFeed,
    addFeedItems,
    setFeedScrollPosition,
    setFoodStripTarget,
    setDarkMode,
    setDemoMode,
    setUniqueId,
    setTrialActive,
    setTrialTime,
    setFoodCount,
    setDeveloperMode,
    setItem,
    setServiceLinks,
    setScrollPosition,
    setFocusedColor,
    setFocusedTitle,
    setUserDemoMode,
    setMapPadding,
    setMapStripTarget,
    addQuickStorageItem, updateQuickStorageItem, removeQuickStorageItem, setQuickStorage,
    setCurrentLocation

} = feedSlice.actions;

export default feedSlice.reducer;
