import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { setFoodCount } from './store/schemas/feedSlice';
import { server } from './helpers/serverDetection';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentLocation } from './store/schemas/feedSlice';
import { SectionIsolationProvider, SectionIsolationWrapper } from '../library/client/platformSeparator';

const RenderSearch = loadable(() => import('./publicPages/search/renderSearch'))
const IsolateContent = loadable(() => import('./publicPages/isolateContext'))
const LandingPage = loadable(() => import('./publicPages/landingPage'))
const Page404 = loadable(() => import('./publicPages/404'))
const Header = loadable(() => import('./publicPages/components/header'))
const PrivacyPolicy = loadable(() => import('./publicPages/privacyPolicy'))
const SubscriptionTerms = loadable(() => import('./publicPages/subscription-terms'))
const Cities = loadable(() => import('./publicPages/cities'))


const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {


        (async () => {
            const response = await fetch(server + "/api?model=consolidation&path=/getVersionNumber", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },

            })
            const results = await response.json()

            dispatch(setFoodCount(results.analyticStatus))


        })()

    }, []);

    return (
        <>
            <Header />
            <Routes>

                <Route element={<SectionIsolationProvider />}>

                    <Route element={<SectionIsolationWrapper section='MainApp' > <IsolateContent /></SectionIsolationWrapper>} >
                        <Route path='/' element={<LandingPage />} />
                        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
                        <Route path='/subscriptionTerms' element={<SubscriptionTerms />} />
                        <Route path='/cities' element={<Cities />} />
                    
                    </Route>

         

                        {process.env.NODE_COMPILE == 'split' ?

                        <Route path="/search/*" element={<SectionIsolationWrapper section='Search' ><RenderSearch /></SectionIsolationWrapper>} />


                    
                    
                        :
                        null }

                    <Route path="*" element={<Page404 />} />

                </Route>
               
             


            </Routes>
        </>


    )
};

export default App;
